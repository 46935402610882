export function removeHtml (text) {
  return text.replace(/.html$/, '')
}

export function extractMessageFromException (error) {
  let message = ''
  if (error.response) {
    const data = error.response.data
    if (data.non_field_errors) {
      message = error.response.data.non_field_errors.join('')
    } else if (data.error) {
      message = data.error
    } else if (data.detail) {
      message = data.detail
    }
  }
  return message || 'Unexpected error. Please contact us using chat on the bottom left corner.'
}

export function formatPrice (value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2
  })
  return formatter.format(value)
}

export const diagramToAnalyticsItem = (diagram, index) => ({
  id: diagram.id,
  name: diagram.name,
  price: diagram.price,
  quantity: 1,
  variant: 'Single user',
  category: 'Diagram',
  list_position: index !== undefined ? index + 1 : undefined
})

export const diagramToGA4Item = (diagram, index) => ({
  item_id: diagram.id,
  item_name: diagram.name,
  price: diagram.price,
  quantity: 1,
  variant: 'Single user',
  item_category: 'Diagram',
  index
})

export const cartItemToAnalyticsItem = (item, index) => ({
  id: item.diagram.id,
  name: item.diagram.name,
  price: item.price,
  variant: item.license ? item.license.description : 'Single user',
  quantity: 1,
  category: 'Diagram',
  list_position: index !== undefined ? index + 1 : undefined,
  coupon: item.code
})

export const cartItemToGA4Item = (item, index) => ({
  item_id: item.diagram.id,
  item_name: item.diagram.name,
  price: item.price,
  item_variant: item.license ? item.license.description : 'Single user',
  quantity: 1,
  item_category: 'Diagram',
  index,
  coupon: item.code
})

export const orderItemToAnalyticsItem = (item, index) => ({
  id: item.diagram_id,
  name: item.diagram_name,
  price: item.final_price,
  variant: item.license ? item.license.description : 'Single user',
  quantity: 1,
  category: 'Diagram',
  list_position: index !== undefined ? index + 1 : undefined,
  coupon: item.code
})

export const orderItemToGA4Item = (item, index) => ({
  item_id: item.diagram_id,
  item_name: item.diagram_name,
  price: item.final_price,
  item_variant: item.license ? item.license.description : 'Single user',
  quantity: 1,
  item_category: 'Diagram',
  coupon: item.code,
  index
})

export const planToAnalyticsItem = plan => ({
  id: plan.id,
  name: plan.name,
  price: plan.payment_cycle_months === 12 ? plan.price_annual : plan.price,
  quantity: 1,
  category: 'Subscription'
})

export const planToGA4Item = plan => ({
  item_id: plan.id,
  item_name: plan.name,
  price: plan.payment_cycle_months === 12 ? plan.price_annual : plan.price,
  quantity: 1,
  item_category: 'Subscription'
})

export function addTrailingSlash (url) {
  return url.replace(/[/]*$/, '/')
}

export function prettyLicenseName (name) {
  const mapping = {
    single: '1 User',
    '1-group': '2 to 5 Users',
    '3-global': 'Over 5 Users'
  }
  return mapping[name]
}

export function shuffleArray (array) {
  return array.sort(() => Math.random() - 0.5)
}

export function iconSvg (url) {
  const iconsMap = {
    'content-ready-ppt': 'business',
    'data-charts-ppt': 'charts',
    'creative-styles-ppt': 'creative',
    'diagrams-powerpoint': 'diagram',
    'icons-powerpoint': 'icons',
    'powerpoint-maps': 'maps',
    new: 'new',
    bundles: 'bundle',
    free: 'free'
  }
  if (iconsMap[url]) {
    const src = require(`assets/images/icons/icon-category-${iconsMap[url]}.svg?raw`)
    return src
  }
}

function getCookie (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}


export function getTextFromEvent (event) {
  return event.target.innerText.trim()
}

export function prettyDays (days) {
  if (days === 1) {
    return '1 day'
  } else if (days < 30) {
    return `${days} days`
  } else if (days < 32) {
    return '1 month'
  } else if (days < 365) {
    return `${Math.round(days / 30)} months`
  } else if (days < 730) {
    return '1 year'
  }
}


export function prettyPaymentPeriod (months) {
  if (months === 1) {
    return 'Monthly'
  } else if (months === 3) {
    return 'Quarterly'
  } else if (months === 6) {
    return 'Semi-annually'
  } else if (months === 12) {
    return 'Annually'
  }
}
